import React from "react"
import { Box, Heading, Text, Button } from "../commonElements"
import styled from "styled-components"
import Backdrop from "./Backdrop"
import { useDispatch, useSelector } from "react-redux"
import { displayModal, triggerResultReset } from "../../redux/modal/actions"

const DesText = styled(Text)`
  letter-spacing: 2.4px;
`

const ResultModal = () => {
  const resultModal = useSelector(state => state.modal.result)
  const dispatch = useDispatch()

  const dismissModal = () => {
    dispatch(displayModal(null))
    dispatch(triggerResultReset(true))
  }
  if (resultModal === null) {
    return null
  }
  return (
    <Backdrop>
      <Box
        maxWidth="60rem"
        width="95%"
        height="35rem"
        backgroundColor="white"
        alignItems="center"
        justifyContent="center"
        borderRadius="1rem"
      >
        <Heading fontSize="3.8rem" mb="0.5rem">
          {resultModal.score < 77 ? "😀 💪" : "👍 🎉"}
        </Heading>
        <Heading fontSize="6.4rem" mb="0rem">
          {resultModal.score}/{resultModal.totalNum}
        </Heading>
        <DesText fontSize="2.6rem">{resultModal.description}</DesText>
        <Button
          onClick={() => {
            dismissModal()
          }}
          mt="3rem"
          fontSize="2.0rem"
          backgroundColor="accent"
          color="white"
          width="25rem"
          borderRadius="5px"
        >
          GET IT
        </Button>
      </Box>
    </Backdrop>
  )
}

export default ResultModal
